import { initStickyHeader } from './initStickyHeader';

export const initMobileMenu = () => {
    // VARS
    const burger = document.querySelector('.js-header-button');
    const closeMenu = document.querySelector('.js-close-menu');
    if (!burger && !closeMenu) return;

    // INITS
    initStickyHeader();

    // LISTENERS
    burger.addEventListener('click', handleOnClickBurger, false);
    closeMenu.addEventListener('click', handleOnClickCloseMenu, false);

    // HANDLERS
    function handleOnClickBurger() {
        toggleBurger(this);
        toggleMobileMenu();
    }

    function handleOnClickCloseMenu() {
        const burger = document.querySelector('.js-header-button');
        if (burger) {
            closeBurger(burger);
        }
        toggleMobileMenu();
    }

    // FUNCTIONS
    function toggleBurger(burger) {
        burger.classList.contains('open') ? closeBurger(burger) : openBurger(burger);
    }

    function openBurger(burger) {
        burger.classList.add('open');
        disableScroll();
    }

    function closeBurger(burger) {
        burger.classList.remove('open');
        enableScroll();
    }

    function toggleMobileMenu() {
        const mobileMenu = document.querySelector('.js-header-menu');
        mobileMenu.classList.contains('open') ? closeMobileMenu(mobileMenu) : openMobileMenu(mobileMenu);
    }

    function openMobileMenu(mobileMenu) {
        mobileMenu.classList.add('open');
        disableScroll();
    }

    function closeMobileMenu(mobileMenu) {
        mobileMenu.classList.remove('open');
        enableScroll();
    }
};
