export const initStickyHeader = () => {
    // INITS
    setStickyHeader();

    // LISTENERS
    window.addEventListener('scroll', handleOnScroll, false);

    // HANDLERS
    function handleOnScroll() {
        setStickyHeader();
    }

    // FUNCTIONS
    function setStickyHeader() {
        const header = document.querySelector('.js-header');
        if (!header) return;
        document.body.classList.contains('disable-scroll')
            ? header.classList.add('sticky')
            : window.scrollY > 0
            ? header.classList.add('sticky')
            : header.classList.remove('sticky');
    }
};
