import { initScrollLocker } from './assets/scrollLocker';
import { initMobileMenu } from './components/initMobileMenu';
import { initTabs } from './components/initTabs';
import { initAccordion } from './components/initAccordion';
import { initContactForm } from './components/initContactForm';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initAppendHiddenInputsToPlanForms } from '../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initScrollLocker();
    initMobileMenu();
    initTabs();
    initAccordion();
    initContactForm();
    initAppendHiddenInputsToPlanForms();
}
