import { getTarget } from '../../helper-function/get-target';

export const initSimpleTabs = () => {
    const allTabNavigation = document.querySelectorAll('[data-tab-navigation]');
    const tabsDropdownLabel = document.querySelectorAll('[data-tabs-label]');

    allTabNavigation.forEach((navigation) => {
        navigation.addEventListener('click', handleOnClickTabNavigation, false);
        navigation.addEventListener('keydown', function (e) {
            if (e.keyCode === 13) {
                handleOnClickTabNavigation(e);
            }
        });
    });

    tabsDropdownLabel.forEach((label) => {
        label.addEventListener('click', handleOnClickLabelDropdown, false);
    });

    document.addEventListener('click', handleOnClickDocument, false);
};

function handleOnClickTabNavigation(e) {
    if (e.currentTarget.classList.contains('is-active')) return;

    const container = e.currentTarget.closest('[data-tabs-container]');
    removeActiveTab(container);
    setActiveTab(container, e.currentTarget);
    closeAllDropdown();
    setActiveLabel(e.currentTarget);
}

function handleOnClickLabelDropdown() {
    toggleDropdown(this);
}

function handleOnClickDocument(e) {
    if (!getTarget(e, '[data-tabs-dropdown]')) {
        closeAllDropdown();
    }
}

function removeActiveTab(container) {
    container.querySelector('.is-active[data-tab-navigation]').classList.remove('is-active');
    container.querySelector('.is-active[data-tab-content]').classList.remove('is-active');
}

function setActiveTab(container, tabNav) {
    const index = tabNav.dataset.tabNavigation;
    tabNav.classList.add('is-active');
    container.querySelector(`[data-tab-content="${index}"]`).classList.add('is-active');
}

function toggleDropdown(label) {
    const dropdown = label.closest('[data-tabs-dropdown]');
    dropdown.classList.contains('is-open-dropdown') ? closeAllDropdown() : openDropdown(dropdown);
}

function openDropdown(dropdown) {
    dropdown.classList.add('is-open-dropdown');
}

function closeAllDropdown() {
    const dropdowns = document.querySelectorAll('[data-tabs-dropdown]');
    dropdowns.forEach((dropdown) => {
        dropdown.classList.remove('is-open-dropdown');
    });
}

function setActiveLabel(dropdownItem) {
    const dropdown = dropdownItem.closest('[data-tabs-dropdown]');
    dropdown.querySelector('[data-tabs-label] span').textContent = dropdownItem.textContent.trim();
}
