export const scrollTo = (selector, smooth = true) => {
    const target = document.querySelector(selector);
    if (!target) return;

    if (smooth) {
        return window.scrollTo({
            top: target.getBoundingClientRect().top + pageYOffset,
            behavior: 'smooth',
        });
    } else {
        return window.scrollTo({
            top: target.getBoundingClientRect().top + pageYOffset,
        });
    }
};
