import anime from 'animejs/lib/anime.es.js';

export const initAccordion = () => {
    // VARS
    const accordionNavs = document.querySelectorAll('[data-accordion-toggle]');
    const duration = 300;

    // LISTENERS
    [...accordionNavs].forEach((nav) => {
        nav.addEventListener('click', handleOnClickAccordionNav, false);
    });

    // HANDLERS
    function handleOnClickAccordionNav() {
        const accordion = this.closest('[data-accordion]');
        if (accordion.classList.contains('is-open')) {
            closeAccordion(accordion);
        } else {
            closeActiveAccordion();
            openAccordion(accordion);
        }
    }

    // FUNCTIONS
    function openAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');
        const height = body.scrollHeight;

        accordion.classList.add('is-open');

        anime({
            targets: body,
            height: [0, height],
            easing: 'linear',
            duration: duration,
        });
    }

    function closeAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');

        accordion.classList.remove('is-open');

        anime({
            targets: body,
            height: 0,
            easing: 'linear',
            duration: duration,
        });
    }

    function closeActiveAccordion() {
        const activeAccordion = document.querySelector('.is-open[data-accordion]');
        if (!activeAccordion) return;
        const activeAccordionBody = activeAccordion.querySelector('[data-accordion-body]');

        activeAccordion?.classList.remove('is-open');

        anime({
            targets: activeAccordionBody,
            height: 0,
            easing: 'linear',
            duration: duration,
        });
    }
};
