import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './reCaptchaActions';

export const initContactForm = () => {
    const form = document.querySelector('#contact-form');
    if (!form) return;

    const formAction = new window.JustValidate('#contact-form', {
        rules: {
            email: {
                required: true,
                email: true,
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        submitHandler: function (form, values, ajax) {
            if (isReCaptchaValid()) {
                removeRecaptchaError('#contact-form');
            } else {
                setRecaptchaError('#contact-form');
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    showMessageSuccessesSend();
                },
                error: function (response) {
                    console.log(response);
                },
            });
        },

        invalidFormCallback: function (errors) {
            if (errors && !isReCaptchaValid()) {
                setRecaptchaError('#contact-form');
            }
        },
    });
};

function resetForm(form) {
    grecaptcha.reset();
    form.reset();
    removeRecaptchaError('#contact-form');
}

function showMessageSuccessesSend() {
    const message = document.querySelector('[data-successfully-sent]');
    message.classList.add('is-show');
}
