import { scrollTo } from '../../helper-function/scrollTo';

export const initOpenAnchorTab = () => {
    const urlHash = window.location.hash;
    const listTabHash = [
        '#app_activity',
        '#advanced_apps_blocking',
        '#wi_fi_security_scan_block',
        '#spyware_scanner',
        '#browser_security_scan_block',
        '#advanced_location_tracking',
    ];

    listTabHash.forEach((hash, index) => {
        if (hash === urlHash) {
            document.querySelector(`[data-tab-navigation="${index}"]`).click();
            scrollTo('.features', false);
        }
    });
};
